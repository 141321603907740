import React, { Component, Fragment } from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import ReactExport from "react-export-excel";
import { registerLocale } from "react-datepicker";
import vi from "date-fns/locale/vi";
import { IP, SERVER } from '../../store/constants/config'
import DateInput from 'date-input';
import moment from "moment";
// import jwtDecode from 'jwt-decode';
import "react-datepicker/dist/react-datepicker.css";
import QrReader from 'react-qr-scanner'
import QRCode from 'react-qr-code';
import Webcam from "react-webcam";
import { w3cwebsocket as W3CWebSocket } from "websocket";
// import "./styles.css";
import classnames from "classnames";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Col,
    Form,
    Input,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Tooltip,
    Alert,
    UncontrolledTooltip,
    Label,
    Collapse
} from 'reactstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Avatar from 'react-avatar-edit'

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';

import * as faceapi from "face-api.js";
import * as canvas from 'canvas';
// import '@tensorflow/tfjs-node';
import ReCAPTCHA from "react-google-recaptcha";

import {
    checkFace,
    fetchFaceDetector,
    fetchLatLong,
    checkFaceStudent
} from '../../store/actions/device';

import {
    fetchDashboard,
    fetchEmployee,
    updateEmployee,
    insertEmployee,
    deleteEmployee,
    fetchProfile,
    updateProfile,
    updateEmployeeApprove,
    updateEmployeeUnapprove,
    fetchTimesheetPerson
} from '../../store/actions/employee';

import {
    // fetchQRCode,
    // updateQRCodePub,
    // updateAvatarCode,
    // scanQRCode,
    // checkQRCode
} from '../../store/actions/qrcode'

import {
    fetchDepartment,
    fetchPosition
} from '../../store/actions/department';

import {
    checkQRCodeStudent
} from '../../store/actions/visitor'


import Camera, { DEVICE, FACING_MODE, PLACEMENT } from 'react-camera-ios';
import 'react-camera-ios/build/styles.css';

import GoogleMapReact from 'google-map-react';
import pin from "./pin.png";
// const { Canvas, Image, ImageData } = canvas
// faceapi.env.monkeyPatch({ Canvas, Image, ImageData })
import axios from "axios";

const sign = require('jwt-encode');

const secret = "ts6nJu7TGes*og$C63NKR412zVhtXsiw5Zd$LC7tk$B^6%WXU1";

const data = {
    type: 'browser',
    name: 'hr',
    time: Math.floor(Date.now() / 1000)
};

const jwt = sign(data, secret);

const AnyReactComponent = () => <div><img style={{
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -100%)"
}} src={pin} alt="pin" /></div>;

const expressionMap = {
    neutral: "😶",
    happy: "😄",
    sad: "😞",
    angry: "🤬",
    fearful: "😖",
    disgusted: "🤢",
    surprised: "😲"
}

class FaceCheck extends Component {

    constructor(props) {
        super(props);
        this.success = this.success.bind(this);
        this.successNoTimesheet = this.successNoTimesheet.bind(this);
        this.successCheckQR = this.successCheckQR.bind(this);
        this.info = this.info.bind(this);
        this.error = this.error.bind(this);
        this.successDetect = this.successDetect.bind(this);
        this.errorDetect = this.errorDetect.bind(this);
        this.errorCheckQR = this.errorCheckQR.bind(this);
        this.onCrop = this.onCrop.bind(this);
        this.onDetectCrop = this.onDetectCrop.bind(this);
        this.onCropAvatar = this.onCropAvatar.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onImageLoad = this.onImageLoad.bind(this);
        this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this);
        this.onFind = this.onFind.bind(this);
        this.handleScan = this.handleScan.bind(this);
        this.onQRClose = this.onQRClose.bind(this);
        this.onEntering = this.onEntering.bind(this);
        this.onEntered = this.onEntered.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.state = {
            time: new Date(Date.now()).toLocaleTimeString(),
            date_current: new Date(Date.now()).toLocaleDateString(),
            is_update_avatar: false,
            isLoadingEmployee: true,
            isAppointmentCode: true,
            lat: "",
            lng: "",
            is_status_check: "",
            isIOS: false,
            session: "",
            dataQRCode: [],
            filterQRCode: [],
            dataFaceCheck: [],
            errors: [],
            intervalID: null,
            text: "Tất cả",
            EmpName: "",
            EmpCode: "TEST",
            employee_name: '',
            department_name: '',
            avatar_base64: "",
            tooltipOpen: false,
            delay: 2000,
            value: '|||||<<<<',
            isScan: true,
            scanOption: "0",
            detectOption: "0",
            Vacxin: "-1",
            src: "",
            isFaceDetect: true,
            isQuetLai: true,
            isRefresh: false,
            isValid: true,
            expressions: [],
            outputImage: "",
            isStepScanQR: false,
            isStepSubmit: false,
            isResult: false,
            iscaptcha: false,
            objOption: "-1",
            accordion: [true, false, false],
            isCheckSubmit: true,
            modal: false,
            modalSuccess: false,
            fetchDepartment: '',
            fetchEmployee: '',
            videoConstraints: { height: 400, facingMode: 'user' },
            columnsFaceCheck: [{
                dataField: "updateV",
                isDummyField: true,
                text: "Vào",
                sort: true,
                headerFormatter: this.columnFormatterC,
                formatter: this.columnButtonFormatterVao
            }, {
                dataField: "updateR",
                isDummyField: true,
                text: "Ra",
                sort: true,
                headerFormatter: this.columnFormatterC,
                formatter: this.columnButtonFormatterRa
            }, {
                dataField: "working_date",
                text: "Ngày",
                sort: true,
                headerFormatter: this.columnFormatter,
                //format lại ngày tháng
                formatter: this.columnDayFormatter,
            }, {
                dataField: "time_enter_format",
                text: "Vào ca",
                sort: true,
                headerFormatter: this.columnFormatter,
            }, {
                dataField: "time_leave_format",
                text: "Ra ca",
                sort: true,
                headerFormatter: this.columnFormatter,
            }, {
                dataField: "total_working_hour",
                text: "Số giờ",
                sort: true,
                headerFormatter: this.columnFormatter,
            }, {
                dataField: "explan_text",
                text: "Giải trình",
                sort: true,
                headerFormatter: this.columnFormatter,
            }
            ]
        };
    }

    columnFormatter = (column, colIndex, { sortElement, filterElement }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: "100px" }}>
                {filterElement}
                {column.text}
            </div>
        );
    }


    columnFormatterC = (column, colIndex, { sortElement, filterElement }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: "50px" }}>
                {filterElement}
                {column.text}
            </div>
        );
    }
    columnButtonUpdateFormatter = (cell, row) => {
        if (row.status_check === "1") {
            return (
                <Button color='success' type='button' > Thành công</Button>
            )
        } else if (row.status_check === "-1") {
            return (
                <Button color='danger' type='button' > Thiết bị chưa nhận dạng</Button>
            )
        } else if (row.status_check === "0") {
            return (
                <Button color='primary' type='button'> Không khớp mã nhận dạng</Button>
            )
        }

    }

    columnButtonFormatterVao = (cell, row) => {
        if (row.check_in !== '') {
            return (

                <Button color='primary' type='button' onClick={() => {
                    this.setState({
                        km: row.distance_in,
                        lat: parseFloat(row.lat_in),
                        address_check: row.check_in,
                        lng: parseFloat(row.long_in),
                        modal: !this.state.modal,
                    })
                }}> Xem</Button>
            )
        } else {
            return (

                <Button color='secondary' type='button' onClick={() => {
                }}> Chưa</Button>
            )
        }

    }

    columnButtonFormatterRa = (cell, row) => {
        if (row.check_out !== '') {
            return (
                <Button color='primary' type='button' onClick={() => {
                    this.setState({
                        km: row.distance_out,
                        lat: parseFloat(row.lat_out),
                        address_check: row.check_out,
                        lng: parseFloat(row.long_out),
                        modal: !this.state.modal,
                    })
                }}> Xem</Button>
            )
        } else {
            return (

                <Button color='secondary' type='button' onClick={() => {
                }}> Chưa</Button>
            )
        }

    }

    onEntering() {
        this.setState({ status: 'Opening...' });
    }

    onEntered() {
        this.setState({ status: 'Opened' });
    }

    onExiting() {
        this.setState({ status: 'Closing...' });
    }

    onExited() {
        this.setState({ status: 'Closed' });
    }

    onFind(value) {
        this.setState({ value, watching: false })
    }

    toggleAccordion(tab) {

        const prevState = this.state.accordion;
        const state = prevState.map((x, index) => tab === index ? !x : false);

        this.setState({
            accordion: state,
        });
    }


    handleScan(value) {
        const {
            isScan,
            isValid
        } = this.state

        if (value !== null && isScan && isValid) {
            this.setState({
                value: value.text,
            }, () => {
                const name = value['text'].split('|')[1]
                if (value['text'].includes('*') || value['text'].split('|').length != 6) {
                    this.errorCheckQR()
                    this.setState({
                        isValid: false
                        // value: null
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                isValid: true
                            })
                        }, 1000)
                    })
                } else {
                    this.successCheckQR()
                    //this.toggleAccordion(2)
                    this.setState({
                        isScan: false,
                        isStepSubmit: true,
                        isCheckSubmit: false,
                    }, () => { this.toggleAccordion(2) })
                }
                // setTimeout(() => {
                //     this.props.onscanQRCode(value.text, response => {
                //         this.setState({
                //             isScan: true
                //         }, () => {
                //         })
                //     })
                // }, 1000)
            })
        }
    }
    onobjOptionChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            const {
                EmpCode,
                EmpName,
                Vacxin,
                objOption
            } = this.state
            //console.log(Vacxin)
            //console.log(EmpCode + '-' + EmpName + '-' + Vacxin)
            if (EmpCode !== "" && EmpName !== "" && Vacxin !== "-1" && objOption !== "-1") {
                this.setState({
                    isFaceDetect: true,
                    isRefresh: false,
                    isStepScanQR: false,
                    isStepSubmit: true,
                }, () => {
                    this.toggleAccordion(0);
                    this.toggleAccordion(1);
                    this.run();
                })
            } else {
                this.setState({
                    isFaceDetect: false,
                    isRefresh: true,
                    isStepScanQR: false,
                    isStepSubmit: false,

                }, () => { this.stop() })
            }
        })
    }
    onScanOptionChange = e => {
        if (e.target.value === "0") {
            this.run()
        } else {
            this.stop()
        }
        this.setState({
            [e.target.name]: e.target.value,
            isCheckSubmit: true,
            isScan: true
        }, () => { })
    }
    onDetectOptionChange = e => {
        if (e.target.value === "0") {
            this.run()
        } else {
            this.stop()
        }
        this.setState({
            [e.target.name]: e.target.value,
            isFaceDetect: true,
            //isCheckSubmit: true,
            isFaceDetect: e.target.value === "0" ? true : false
        }, () => { })
    }
    handleError(err) {
        console.error(err)
    }

    toggle() {
        //console.log(this.state.tooltipOpen)
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });
    }
    onBeforeFileLoad(elem) {
        if (elem.target.files[0].size < 10000) {
            alert("Kích thước hình quá nhỏ !")
        };
    }
    onClose() {
        this.setState({ Image: "", preview: "" })
    }

    onQRClose() {
        this.setState({ ImageQR: "", preview: "" })
    }
    onHuy = () => {
        this.stop()
        this.setState({
            isResult: false,
            isFaceDetect: false,
            isQuetLai: true,
        }, () => {
            let path = `/#/Home`;
            window.location.href = path

        })
    }

    onRegisDevClick = () => {
        this.setState({

        }, () => {
            const {
                Image,
                EmpCode
            } = this.state            
            //console.log(Image)
            let err = 0

            if (Image === "" || Image === undefined) {
                err = 1
                alert('Vui lòng upload hình ảnh nhận diện !')
            }
            // if (EmpCode === "" || EmpCode === undefined) {
            //     err = 2
            //     alert('Vui lòng nhập MSSV !')
            // }



            if (err === 0) {
                this.checkSubmitQR()
                this.setState({
                    //iscaptcha: true
                }, () => {                    
                    this.onChangeCaptcha()
                })
            }
        })
    }

    onChangeCaptcha = (value) => {
        // console.log("Captcha value:", value);       
        let dataFaceCheckArr = []
        this.checkTimesheet()        
        // const client = new W3CWebSocket('wss://triviet.ailab.vn:5020/ws/facecheck/' + session);
        const client = new W3CWebSocket('wss://triviet.ailab.vn:5020/ws/facecheck');

        client.onopen = () => {
            console.log('WebSocket Client Connected');            
            this.setState({
                // modal: !this.state.modal
                // iscaptcha: false,                        
                // isResult: true,
                // isFaceDetect: true,
                // isScan: true,
                // isRefresh: false,
                isQuetLai: true,
                message_log: ''
            }, () => {
                const {
                    Image,
                    EmpCode,
                    lat,
                    lng,
                    session
                } = this.state


                //console.log('tới đây rồi')
                client.onmessage = (message) => {
                    console.log('tới đây rồi')
                    this.setState({
                        isQuetLai: false
                    }, () => {
                        console.log(message.data)
                        if (message.data.split('|')[0] === 'facecheck') {
                            this.setState({
                                isLoadingFaceCheck: true,
                            }, () => {
                                const device_name = message.data.split('|')[1]
                                const employee_code = message.data.split('|')[2]
                                const status = message.data.split('|')[3]
                                const session_match = message.data.split('|')[8]


                                console.log(status)

                                if (String(session_match) === String(session)) {
                                    if (status === "no_meet_conditions_person") {
                                        dataFaceCheckArr = {
                                            device_name,
                                            employee_code,
                                            status,
                                            name: "Không thể nhận dạng",
                                            idcard: "Không thể nhận dạng",
                                            status_check: '-1'
                                        }
                                        //client.close()
                                        this.error('Chưa đăng ký thông tin')

                                        this.setState({
                                            iscaptcha: false,
                                            isFaceDetect: true,
                                            isStepScanQR: false,
                                            isQuetLai: true,
                                        }, () => { this.run() })

                                    }

                                    else if (status === 'meet_conditions_person') {
                                        // if (message.data.split('|')[6] === 'valid') {
                                        const idcard = message.data.split('|')[4]
                                        const name = message.data.split('|')[5]
                                        const km = message.data.split('|')[7]
                                        const address_check = message.data.split('|')[9]
                                        console.log(address_check)
                                        //const ma_doi_tuong = message.data.split('|')[2]

                                        this.setState({
                                            // modalSuccess: !this.state.modalSuccess,
                                            km,
                                            address_check,
                                            EmpCode: idcard
                                        }, () => {
                                            let status_check = '1'
                                            if (EmpCode.toUpperCase() !== idcard.toUpperCase()) {
                                                status_check = '0'
                                            }

                                            dataFaceCheckArr = {
                                                device_name,
                                                employee_code,
                                                status,
                                                name,
                                                idcard,
                                                status_check
                                            }

                                            this.success()
                                            client.close()
                                            const day = new Date()
                                            const endDate = moment().format('YYYY-MM-DD');
                                            const startDate = moment(new Date(day.setDate(day.getDate() - 30))).format('YYYY-MM-DD');

                                            this.props.onfetchTimesheetPerson(idcard, startDate, endDate, dataFaceCheck => {
                                                console.log(dataFaceCheck)
                                                this.setState({
                                                    employee_name: dataFaceCheck[0]['employee_name'],
                                                    department_name: dataFaceCheck[0]['department_name'],
                                                    dataFaceCheck: dataFaceCheck,
                                                    //modal: !this.state.modal,
                                                    isLoadingFaceCheck: false,
                                                    iscaptcha: false,
                                                    isResult: true,
                                                    isQuetLai: true
                                                }, () => {
                                                    setTimeout(() => {
                                                        this.setState({
                                                            modal: false,
                                                            modalSuccess: false
                                                        })
                                                    }, 10000)
                                                })
                                            })
                                        })

                                    }
                                    else if (status === 'no_timesheet_in_web') {
                                        // if (message.data.split('|')[6] === 'valid') {
                                        const idcard = message.data.split('|')[4]
                                        const name = message.data.split('|')[5]
                                        const km = message.data.split('|')[7]
                                        const address_check = message.data.split('|')[9]
                                        console.log(address_check)
                                        //const ma_doi_tuong = message.data.split('|')[2]

                                        this.setState({
                                            // modalSuccess: !this.state.modalSuccess,
                                            km,
                                            address_check,
                                            EmpCode: idcard
                                        }, () => {
                                            let status_check = '1'
                                            if (EmpCode.toUpperCase() !== idcard.toUpperCase()) {
                                                status_check = '0'
                                            }

                                            dataFaceCheckArr = {
                                                device_name,
                                                employee_code,
                                                status,
                                                name,
                                                idcard,
                                                status_check
                                            }

                                            this.successNoTimesheet()
                                            client.close()
                                            const day = new Date()
                                            const endDate = moment().format('YYYY-MM-DD');
                                            const startDate = moment(new Date(day.setDate(day.getDate() - 30))).format('YYYY-MM-DD');

                                            this.props.onfetchTimesheetPerson(idcard, startDate, endDate, dataFaceCheck => {
                                                this.setState({
                                                    employee_name: dataFaceCheck[0]['employee_name'],
                                                    department_name: dataFaceCheck[0]['department_name'],
                                                    dataFaceCheck: dataFaceCheck,
                                                    //modal: !this.state.modal,
                                                    isLoadingFaceCheck: false,
                                                    iscaptcha: false,
                                                    isResult: true,
                                                    isQuetLai: true
                                                }, () => {
                                                    setTimeout(() => {
                                                        this.setState({
                                                            //modal: false,
                                                            //modalSuccess: false
                                                        })
                                                    }, 10000)
                                                })
                                            })
                                        })
                                    }
                                }
                            })
                        }
                    })

                };

                this.props.oncheckFace(Image, session, lat, lng, response => {
                    // console.log(response)
                    this.setState({
                        //isRefresh: true,
                    }, () => {
                        this.setState({
                            // isResult: true,
                        }, () => {
                            this.stop()
                            // update khoảng cách và địa điểm chấm công
                            setTimeout(() => {
                                this.setState({
                                    // Image:'',
                                    // EmpCode:'',
                                    // preview: "",
                                    // isFaceDetect: true,
                                    // isResult: false,
                                    // isLoadingEmployee: false
                                })
                            }, 20000)
                            //this.run();
                        })

                    })

                })
            })
        };

    }

    onCrop(ImageQR) {
        this.checkSubmitQR()
        let data = ImageQR.replace(/^data:image\/png;base64,/, "")
        this.setState({
            ImageQR: data,
            isCheckSubmit: true,
            //isQuetLai: false
        }, () => {
            const {
                ImageQR,
                //idcard
            } = this.state

            // console.log(ImageQR)
            let err = 0

            if (ImageQR === "" || ImageQR === undefined) {
                err = 1
                alert('Vui lòng upload mã QR !')
            }
            if (err === 0) {
                this.props.oncheckQRCode(ImageQR, response => {
                    // console.log(response)
                    if (response.status === "fail") {
                        this.setState({
                            ImageQR: "",
                        }, () => this.errorCheckQR())
                    } else if (response.status === "success") {
                        this.setState({
                            value: response.qrcode,
                            isStepSubmit: true,
                            isCheckSubmit: false,
                            isScan: false,
                            isQuetLai: false
                        }, () => { this.successCheckQR() })
                        this.toggleAccordion(2)

                    }
                }
                )

            }
        })
    }

    onDetectCrop(Image) {
        // this.checkSubmitQR()
        let data = (Image.replace(/^data:image\/png;base64,/, "")).replace(/^data:image\/jpeg;base64,/, "")
        //console.log(data)
        this.setState({
            isQuetLai: true
        }, () => {
            this.props.onfetchFaceDetector(data, Image => {
                //   console.log(Image)         
                const status = Image['status']
                if (status === 'success') {

                    let Image_base64 = "data:image/png;base64," + Image['encoded_img']
                    this.setState({
                        src: data,
                        Image: Image_base64,
                        preview: Image_base64,
                        outputImage: Image_base64,
                        isFaceDetect: false,
                        isStepScanQR: true,
                        isQuetLai: false
                    }, () => {
                        this.successDetect()
                        this.onRegisDevClick()
                    })
                    this.toggleAccordion(1);
                    this.toggleAccordion(2);
                } else {
                    this.errorDetect()
                }

            })
        })


    }

    onChupAnhDetect = () => {
        const Image = this.video.current.getScreenshot()
        let data = (Image.replace(/^data:image\/png;base64,/, "")).replace(/^data:image\/jpeg;base64,/, "")
        //console.log(data)
        this.setState({
            isQuetLai: true
        }, () => {
            this.props.onfetchFaceDetector(data, Image => {
                //   console.log(Image)         
                const status = Image['status']
                if (status === 'success') {

                    let Image_base64 = "data:image/png;base64," + Image['encoded_img']
                    this.setState({
                        src: data,
                        Image: Image_base64,
                        preview: Image_base64,
                        outputImage: Image_base64,
                        isFaceDetect: false,
                        isStepScanQR: true,
                        isQuetLai: false
                    }, () => {
                        //this.successDetect()
                        this.onRegisDevClick()
                    })
                    this.toggleAccordion(1);
                    this.toggleAccordion(2);
                } else {
                    this.errorDetect()
                }

            })
        })
    }

    onCropAvatar(Image) {
        let data = Image.replace(/^data:image\/png;base64,/, "")
        this.setState({
            Image: data
        }, () => {
        })
    }

    onImageLoad(Image) {
        let data = Image.replace(/^data:image\/png;base64,/, "")
        this.setState({
            Image: data
        })
    }
    onRegisDevCancel = () => {
        this.setState({
            // isLoadingDevice: true
            Avatar: "", Image: "", preview: "",
            idcard: "",
            name: "",
            gender: "1",
            position_name: "",
            department_name: "",
            Vacxin: '-1',
            EmpValue: { value: "-1", label: '--Vui lòng chọn nhân viên' },
        }, () => { })
    }

    onInputChange = e => {
        e.preventDefault()
        this.setState({
            [e.target.name]: e.target.value,
        }, () => {
        })
    }

    success() {
        // add type: 'success' to options
        // positioning: https://github.com/fkhadra/react-toastify#positioning-toast
        toast.dismiss()
        return toast.success('Điểm danh thành công... ', {
            position: toast.POSITION.TOP_CENTER
        });
    }

    successNoTimesheet() {
        // add type: 'success' to options
        // positioning: https://github.com/fkhadra/react-toastify#positioning-toast
        toast.dismiss()
        return toast.error('Bạn không được điểm danh trên web! Lần điểm danh này sẽ không được ghi nhận... ', {
            position: toast.POSITION.TOP_CENTER
        });
    }

    info(message) {
        // this.clear()
        // add type: 'info' to options
        return toast.info(message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000
        });
    }

    successDetect() {
        // add type: 'success' to options
        // positioning: https://github.com/fkhadra/react-toastify#positioning-toast
        return toast.success('Nhận diện thành công... ', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000
        });
    }

    error(text) {
        // add type: 'error' to options
        return toast.error(text, {
            position: toast.POSITION.TOP_CENTER
        });
    }

    errorDetect() {
        // add type: 'error' to options
        return toast.error('Không nhận diện được khuôn mặt ! Vui lòng thử lại ! ', {
            position: toast.POSITION.TOP_CENTER
        });
    }



    errorCheckQR() {
        return toast.error('Mã QR không hợp lệ ! Vui lòng phóng to và thử lại !', {
            position: toast.POSITION.TOP_CENTER
        });
    }

    checkSubmitQR() {
        return toast.info('Đang xử lý ...', {
            position: toast.POSITION.TOP_CENTER
        });
    }

    checkTimesheet() {
        toast.dismiss()
        return toast.info('Đang xử lý công vui lòng chờ ...', {
            position: toast.POSITION.TOP_CENTER, autoClose: 100000
        });
    }

    successCheckQR() {
        // add type: 'success' to options
        // positioning: https://github.com/fkhadra/react-toastify#positioning-toast
        return toast.success('Mã QR Hợp lệ !', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
        });
    }

    // UNSAFE_componentWillMount() {
    //     client.onopen = () => {
    //         console.log('WebSocket Client Connected');
    //     };
    //     client.onmessage = (message) => {
    //         if(message.data === 'none'){
    //             this.errorCheckQR()
    //         }
    //         else if (message.data === 'test')
    //         {
    //             this.successCheckQR()
    //         }
    //         console.log(message);
    //         // this.info()
    //     };
    // }

    video = React.createRef();

    // log = (...args) => {
    //     console.log(...args);
    // };

    run = async () => {
        console.log("run started");
        try {
            await faceapi.nets.tinyFaceDetector.load("/models/");
            // await faceapi.loadFaceExpressionModel(`/models/`);
            this.mediaStream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: "user" }
            });

            this.video.current.srcObject = this.mediaStream;
        } catch (e) {
            console.log(e.name, e.message, e.stack);
        }
    };


    stop = async () => {
        console.log("stopped");
        try {
            const stream = this.video.current.srcObject;
            const tracks = stream.getTracks();

            tracks.map(track => {
                track.stop()
            })

            this.video.current.srcObject = null;
            this.setState({
                isFaceDetect: false,
                isStepScanQR: true,
                //isQuetLai: true
            })
        } catch (e) {
            console.log(e.name, e.message, e.stack);
        }
    };


    onPlay = async () => {
        const {
            isFaceDetect
        } = this.state

        if (isFaceDetect) {
            if (this.video.current !== null) {
                // console.log(this.video.current)
                if (
                    this.video.current.paused ||
                    this.video.current.ended ||
                    !faceapi.nets.tinyFaceDetector.params
                ) {
                    setTimeout(() => this.onPlay());
                    return;
                }
                else {
                    const options = new faceapi.TinyFaceDetectorOptions({
                        inputSize: 512,
                        scoreThreshold: 0.5
                    });

                    const input = document.getElementById('webCamera')

                    // console.log(input.videoWidth, input.videoHeight)
                    const result = await faceapi
                        .detectSingleFace(input, options)


                    if (result && String(input.videoWidth) !== "0" && String(input.videoHeight) !== "0") {
                        // console.log(result)
                        const detectionsForSize = faceapi.resizeResults(result, { width: input.videoWidth, height: input.videoHeight })
                        // draw them into a canvas
                        // const canvas= faceapi.createCanvasFromMedia(document.getElementById('webCamera'))
                        const canvas = document.getElementById('overlay')
                        canvas.width = input.videoWidth
                        canvas.height = input.videoHeight
                        // canvas.getContext('2d').clearRect(0,0,canvas.width,canvas.height)
                        // faceapi.matchDimensions(canvas,detectionsForSize)
                        faceapi.draw.drawDetections(canvas, detectionsForSize, { withScore: true })
                        // const canvasface = faceapi.extractFaces(input,detectionsForSize)
                        this.setState({

                        }, async () => {
                            const box = detectionsForSize.box
                            const threshold = result.classScore
                            if (threshold >= 0.8) {
                                const regionsToExtract = [
                                    new faceapi.Rect(box.x, box.y - (0.2 * box.y), box.width, box.height + (0.2 * box.height))
                                ];
                                const faceImages = await faceapi.extractFaces(input, regionsToExtract);

                                if (faceImages.length === 0) {
                                    // console.log("No face found");
                                } else {
                                    let outputImage = "";
                                    // console.log(faceImages)
                                    faceImages.map((cnv, index) => {
                                        // console.log(cnv)
                                        outputImage = cnv.toDataURL();
                                        // console.log(cnv.toDataURL());
                                    });
                                    // setPic(faceImages.toDataUrl);
                                    // console.log("face found ");                                
                                    this.toggleAccordion(1);
                                    this.toggleAccordion(2);
                                    this.setState({
                                        outputImage: outputImage,
                                        Image: outputImage,
                                        isQuetLai: false
                                    }, () => {
                                        this.stop()
                                        this.onRegisDevClick()
                                    })
                                }
                            }
                        })
                        setTimeout(() => {
                        }, 200);
                    }

                    setTimeout(() => this.onPlay(), 200);
                }
            }
        }

    };

    // startVideo() {
    //     navigator.getUserMedia(
    //         { video: {} },
    //         stream => video.srcObject = stream,
    //         err => console.log(err)
    //     )
    // }


    render() {

        registerLocale("vi", vi);

        const {
            time,
            date_current,
            isLoadingEmployee,
            detectOption,
            EmpCode,
            isRefresh,
            isFaceDetect,
            isQuetLai,
            iscaptcha,
            isResult,
            employee_name,
            department_name,
            isIOS,
            dataFaceCheck,
            columnsFaceCheck,
            modal,
            lat,
            lng,
            videoConstraints,
            isAppointmentCode,
            is_status_check
            // isLoadingFaceCheck
        } = this.state


        // console.log(isLoadingFaceCheck)

        // let { year,month,day } = this.state;
        // if (!!!year) { year = "YYYY" };
        // if (!!!month) { month = "MM" };
        // if (!!!date) { date = "DD" };

        const containerStyle = {
            zIndex: 1999
        };

        const previewStyle = {
            height: 300,
            width: 300,
        }

        const containerStyleIos = { display: 'flex', height: '400px', width: '100%', alignItems: 'center', alignContent: "center", justifyContent: "center" };

        return (
            <div className="animated fadeIn" style={{ position: 'absolute', left: '0', width: '100%', overflow: 'hidden' }}>
                <ToastContainer position="top-center" autoClose={5000} style={containerStyle} />
                {isLoadingEmployee && (
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
                )}
                {/* {(!isLoadingEmployee && isAppointmentCode && is_status_check === 'exists_status') && (
                    <Card style={{ margin: '25px' }}>
                    <CardHeader>Vui lòng điền Mã lớp học</CardHeader>
                    <CardBody>
                    <FormGroup
                        style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center'
                        }}
                    >
                        <Col md={12} xs={8}>
                        <Input
                            size={'lg'}
                            type='text'
                            placeholder='Mã lớp học'
                            autoComplete=''
                            // value={AppointmentCode}
                            name='AppointmentCode'
                            id='AppointmentCode'
                            onChange={this.onChangeCodeValue}
                            maxLength={8}
                        />
                        </Col>
                    </FormGroup>
                    <FormGroup
                        style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center'
                        }}
                    >
                        <Col md={12} xs={8}>
                        <Button
                            onClick={this.onSubmitCode}
                            // size="sm"
                            color='success'
                            style={{ marginRight: '5px', width: '100%' }}
                        >
                            {' '}
                            Gửi
                        </Button>
                        </Col>
                    </FormGroup>
                    </CardBody>
                    <CardFooter></CardFooter>
                </Card>
                )} */}
                {(!isLoadingEmployee && isAppointmentCode && is_status_check !== 'exists_valid') && (
                    <Card style={{ display: "flex", margin: '50px'}}>
                    <CardHeader>Thông báo</CardHeader>
                    <CardBody>
                    {/* <FormGroup
                        style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center'
                        }}
                    >
                       
                        
                    </FormGroup>               */}
                            {is_status_check === 'exists_not_today' && (
                                <Col md={12} style={{height: "100%"}}>
                                    <Alert color='info' >
                                        Hôm nay không có tiết học
                                    </Alert>
                                </Col>
                            )}
                            {is_status_check === 'exists_invalid' && (
                                <Col md={12} style={{height: "100%"}}>
                                <Alert color='info'>
                                    Hết thời gian điểm danh
                                </Alert>
                                </Col>
                            )}
                    </CardBody>
                    {/* <CardFooter></CardFooter> */}
                </Card>
                )}
                {(!isLoadingEmployee && !isAppointmentCode) && (
                    <Fragment>
                        <Modal isOpen={this.state.modal} toggle={() => {
                            this.setState({
                                modal: !this.state.modal
                            })
                        }}
                            className={'modal-info ' + this.props.className}
                        >
                            <ModalHeader toggle={() => {
                                this.setState({
                                    modal: !this.state.modal
                                })
                            }}>Vị trí chấm công</ModalHeader>
                            <ModalBody>
                                <Alert color='warning'>
                                    Bạn đang cách vị trí cần chấm công {this.state.km} km.
                                    Bạn gần vị trí chấm công:  {this.state.address_check}
                                </Alert>
                                <div style={{ height: "400px", width: "100%" }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{
                                            key: "AIzaSyC1wNeNM5usBCSU0FPZWw-NRj2lRMzm1sg"
                                        }}
                                        defaultCenter={{
                                            lat: lat,
                                            lng: lng
                                        }}
                                        defaultZoom={16}
                                    >
                                        <AnyReactComponent
                                            lat={lat}
                                            lng={lng}
                                        />
                                    </GoogleMapReact>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                            </ModalFooter>
                        </Modal>

                        <Modal isOpen={this.state.modalSuccess} toggle={() => {
                            this.setState({
                                modalSuccess: !this.state.modalSuccess
                            })
                        }}
                            className={'modal-info ' + this.props.className}
                        >
                            <ModalHeader toggle={() => {
                                this.setState({
                                    modalSuccess: !this.state.modalSuccess
                                })
                            }}>Chấm công thành công</ModalHeader>
                            <ModalBody>
                                <Alert color='success'>
                                    <div style={{ textAlign: 'center' }}>
                                        <img width="35%" src={SERVER + `/employee/avatar?avatar_path=` + 'success.png'} />
                                    </div>
                                </Alert>
                            </ModalBody>
                            <ModalFooter>
                            </ModalFooter>
                        </Modal>


                        {!isResult && (
                            <Fragment>
                                <Card style={{ height: '1200px', display: 'flex', }}>
                                    <CardBody>
                                        {/* <div style={{ textAlign: 'center' }}>
                                            <img width="100%" src={SERVER + `/employee/avatar?avatar_path=` + 'logo10.png'} />
                                        </div> */}

                                        <Row>
                                            <Col md={2}></Col>
                                            <Col md={8}>

                                                {/* <Row>
                                                    <Col xs={12}>
                                                        <div >
                                                            <h2 style={{ textAlign: 'center', fontSize: '20px', color: 'red' }}> {time} - {date_current} </h2>

                                                        </div>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Button style={{ textAlign: 'right'}} onClick={this.onHuy} color='link'>  <b>Trở về</b></Button> 
                                                        <Button
                                                            onClick={this.onHuy}
                                                            // size="sm"
                                                            color="primary"
                                                            style={{ marginRight: "5px", width: "100%" }} > Trở về
                                                        </Button>
                                                    </Col>
                                                </Row> */}

                                                <Card style={{
                                                    // minHeight: '500px', 
                                                    // display: 'flex', 
                                                }}>
                                                    {/* <CardHeader id="headingOne">


                                                        <Button block color="link" className="text-left m-0 p-0"
                                                            onClick={() => this.toggleAccordion(1)} aria-expanded={this.state.accordion[1]} aria-controls="collapseOne"
                                                            type='button'
                                                        
                                                        >
                                                            <Row>
                                                                <Col xs={8}>
                                                                    <h5 style={{ textAlign: 'left', padding: '5%', color: 'red' }}> {time} - {date_current} </h5>

                                                                </Col>
                                                                <Col xs={4} style={{ textAlign: 'right' }}>
                                                                    <Button
                                                                        onClick={this.onHuy}
                                                                        
                                                                        color="primary"
                                                                        style={{ marginRight: "5px", width: "100%" }} > Trở về
                                                                    </Button>
                                                                </Col>
                                                            </Row>

                                                        </Button>
                                                    </CardHeader> */}

                                                    <FormGroup row>
                                                        <Col md={12}>
                                                            <CardBody>
                                                                <FormGroup row>
                                                                    <Col md={12}>
                                                                        {/* <FormGroup row>
                                                                            <Col xs={4} md={4}>
                                                                                <Button style={{ 'width': '100%' }} type="button" color="primary">Mã NV</Button>
                                                                            </Col>
                                                                            <Col xs={8} md={8}>
                                                                                <Input
                                                                                    value={EmpCode}
                                                                                    type="text"
                                                                                    placeholder="Mã nhân viên"
                                                                                    name="EmpCode"
                                                                                    onChange={this.onInputChange}
                                                                                />
                                                                            </Col>
                                                                        </FormGroup> */}

                                                                        {(detectOption === "0") && (
                                                                            <Fragment>
                                                                                {isFaceDetect && (
                                                                                    <Row>
                                                                                        <Col xs={12}>
                                                                                            <FormGroup row style={{ display: 'flex', alignItems: 'center', alignContent: "center", justifyContent: "center" }}>
                                                                                                {!isIOS && (
                                                                                                    <video
                                                                                                        style={{
                                                                                                            width: "100%",
                                                                                                            height: "400px"
                                                                                                        }}
                                                                                                        ref={this.video}
                                                                                                        autoPlay
                                                                                                        muted
                                                                                                        onPlay={this.onPlay}
                                                                                                        playing={isFaceDetect}
                                                                                                        id="webCamera"
                                                                                                    />
                                                                                                )}
                                                                                                {isIOS && (
                                                                                                    <Fragment>
                                                                                                        {/* <Camera
                                                                                                            style={{
                                                                                                                width: "100%",
                                                                                                                height: "400px"
                                                                                                            }}
                                                                                                            id="webCamera"
                                                                                                            facingMode={FACING_MODE.USER}
                                                                                                            // placement={PLACEMENT.CONTAIN}                                                                                                                        
                                                                                                            quality="1"
                                                                                                            onError={error => alert(error)}
                                                                                                            onTakePhoto={
                                                                                                                (dataUrl) => { this.onDetectCrop(dataUrl) }}
                                                                                                        /> */}

                                                                                                        <div style={containerStyleIos}>
                                                                                                            <Webcam
                                                                                                                audio={false}
                                                                                                                screenshotFormat="image/jpeg"
                                                                                                                style={{
                                                                                                                    height: "100%"
                                                                                                                }}
                                                                                                                // width={'100%'}
                                                                                                                videoConstraints={videoConstraints}
                                                                                                                ref={this.video}
                                                                                                            // onPlay={this.onPlay}
                                                                                                            // playing={false}
                                                                                                            // id="webCamera"
                                                                                                            >
                                                                                                            </Webcam>
                                                                                                        </div>
                                                                                                        <br />
                                                                                                        <Row>
                                                                                                            <Col><Button color="secondary" onClick={this.onChupAnhDetect}>Chụp ảnh</Button></Col>
                                                                                                        </Row>

                                                                                                    </Fragment>
                                                                                                )}
                                                                                                <canvas id="overlay"
                                                                                                    style={{ position: "absolute", height: "400px" }}
                                                                                                />
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                    </Row>

                                                                                )}
                                                                                {!isFaceDetect && !isRefresh && (
                                                                                    <Fragment>
                                                                                        <Col>
                                                                                            <FormGroup row style={{ display: 'flex', alignItems: 'center', alignContent: "center", justifyContent: "center" }}>
                                                                                                <img width="200"
                                                                                                    height="200"
                                                                                                    src={this.state.outputImage} />
                                                                                            </FormGroup>
                                                                                            {isQuetLai && (
                                                                                                <FormGroup row style={{ display: 'flex', alignItems: 'center', alignContent: "center", justifyContent: "center" }}>
                                                                                                    <Button
                                                                                                        onClick={() => {
                                                                                                            this.setState({
                                                                                                                isFaceDetect: true,
                                                                                                                isStepScanQR: false,
                                                                                                            }, () => { this.run() })
                                                                                                        }}
                                                                                                        // size="sm"
                                                                                                        color="secondary"
                                                                                                        style={{ marginRight: "5px" }} > Quét lại
                                                                                                    </Button>
                                                                                                </FormGroup>
                                                                                            )}
                                                                                            {!isQuetLai && (
                                                                                                <FormGroup row style={{ display: 'flex', alignItems: 'center', alignContent: "center", justifyContent: "center" }}>
                                                                                                    <Button
                                                                                                        onClick={() => {
                                                                                                            this.setState({
                                                                                                                isFaceDetect: true,
                                                                                                                isStepScanQR: false,
                                                                                                            }, () => { this.run() })
                                                                                                        }}
                                                                                                        disabled
                                                                                                        // size="sm"
                                                                                                        color="secondary"
                                                                                                        style={{ marginRight: "5px" }} > Quét lại
                                                                                                    </Button>
                                                                                                </FormGroup>
                                                                                            )}

                                                                                        </Col>
                                                                                    </Fragment>
                                                                                )}
                                                                            </Fragment>
                                                                        )}
                                                                        {(detectOption === "1") && (
                                                                            <FormGroup row style={{ display: 'flex', alignItems: 'center', alignContent: "center", justifyContent: "center", marginTop: "60px" }}>
                                                                                {!isRefresh && (
                                                                                    <Avatar
                                                                                        // width={"120"}
                                                                                        //img={src}
                                                                                        //closeIconColor='black'
                                                                                        minCropRadius={200}
                                                                                        exportAsSquare={true}
                                                                                        height={"240"}
                                                                                        imageHeight={"240"}
                                                                                        onCrop={this.onDetectCrop}
                                                                                        onClose={this.onClose}
                                                                                    //onImageLoad={this.onImageLoad}
                                                                                    // onBeforeFileLoad={this.onBeforeFileLoad}
                                                                                    />
                                                                                )}
                                                                                {isRefresh && (
                                                                                    <div className="sk-cube-grid">
                                                                                        <div className="sk-cube sk-cube1"></div>
                                                                                        <div className="sk-cube sk-cube2"></div>
                                                                                        <div className="sk-cube sk-cube3"></div>
                                                                                        <div className="sk-cube sk-cube4"></div>
                                                                                        <div className="sk-cube sk-cube5"></div>
                                                                                        <div className="sk-cube sk-cube6"></div>
                                                                                        <div className="sk-cube sk-cube7"></div>
                                                                                        <div className="sk-cube sk-cube8"></div>
                                                                                        <div className="sk-cube sk-cube9"></div>
                                                                                    </div>
                                                                                )}
                                                                            </FormGroup>
                                                                        )}
                                                                    </Col>
                                                                </FormGroup>

                                                            </CardBody>
                                                        </Col>
                                                    </FormGroup>
                                                </Card>

                                                <Button block color="link" className="text-left m-0 p-0"
                                                    onClick={() => this.toggleAccordion(1)} aria-expanded={this.state.accordion[1]} aria-controls="collapseOne"
                                                    type='button'
                                                // className="badge badge-primary"
                                                >
                                                    <Row>
                                                        <Col xs={8}>
                                                            {/* <h5 className="m-0 p-0">Hình nhận diện</h5> */}
                                                            <h5 style={{ textAlign: 'left', padding: '5%', color: 'red' }}> {time} - {date_current} </h5>

                                                        </Col>
                                                        <Col xs={4} style={{ textAlign: 'right' }}>
                                                            <Button
                                                                onClick={this.onHuy}
                                                                // size="sm"
                                                                color="primary"
                                                                style={{ marginRight: "5px", width: "100%" }} > Trở về
                                                            </Button>
                                                        </Col>
                                                    </Row>

                                                </Button>


                                                <FormGroup row>
                                                    <Col md={4}>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Row>
                                                            <Col xs="12">
                                                                {/* <Button
                                                                    onClick={this.onRegisDevClick}
                                                                    // size="sm"
                                                                    color="primary"
                                                                    style={{ marginRight: "5px", width: "100%" }} >
                                                                    <i className="fa fa-plus-square"></i> Kiểm tra nhận diện
                                                                </Button> */}
                                                                {/* <Button
                                                                    onClick={this.onHuy}
                                                                    // size="sm"
                                                                    color="secondary"
                                                                    style={{ marginRight: "5px", width: "20%" }} > Trở về
                                                                </Button> */}
                                                            </Col>
                                                        </Row>
                                                        <p></p>
                                                        {/* {iscaptcha && (
                                                            <Fragment>
                                                                <p></p>
                                                                <ReCAPTCHA
                                                                    sitekey="6LdBXOwiAAAAAC10SVS5PO9TV4Y-5JAJ3iWtQLtn"
                                                                    onChange={this.onChangeCaptcha}
                                                                // size="invisible"
                                                                />
                                                            </Fragment>
                                                        )} */}
                                                    </Col>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                    </CardBody>
                                </Card>


                            </Fragment>
                        )}
                        {isResult && (
                            <Fragment>
                                <Card style={{ height: '800px', display: 'flex', }}>
                                    <CardHeader>
                                        <Button type='button' className="badge badge-primary">Kết quả nhận diện</Button>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={8}>
                                                <div style={{ textAlign: 'left' }}>
                                                    <h5 style={{ verticalAlign: "center" }}>KẾT QUẢ CHẤM CÔNG</h5>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                {/* <Button style={{ textAlign: 'right'}} onClick={this.onHuy} color='link'>  <b>Trở về</b></Button> */}
                                                <Button
                                                    onClick={this.onHuy}
                                                    // size="sm"
                                                    color="primary"
                                                    style={{ marginRight: "5px", width: "100%" }} > Trở về
                                                </Button>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col md={1}></Col>
                                            <Col md={8}>

                                                <FormGroup row>
                                                    <Col xs={4}>Mã: </Col>
                                                    <Col xs={8}>{EmpCode.split('_')[0]}</Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col xs={4}>Tên: </Col>
                                                    <Col xs={8}>{employee_name}</Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col xs={4}>Phòng ban: </Col>
                                                    <Col xs={8}>{department_name}</Col>
                                                </FormGroup>

                                            </Col>
                                        </Row>
                                        <div style={{ overflow: "auto", position: 'relative' }}>
                                            <ToolkitProvider
                                                onDataSizeChange={this.handleDataChange}
                                                keyField="employee_id"
                                                data={dataFaceCheck}
                                                columns={columnsFaceCheck}
                                                columnToggle
                                                exportCSV
                                                search
                                            >
                                                {props => (
                                                    <div>
                                                        <BootstrapTable
                                                            {...props.baseProps}
                                                            wrapperClasses="table-responsive"
                                                            filter={filterFactory()}
                                                            pagination={paginationFactory()}
                                                        />
                                                    </div>
                                                )}
                                            </ToolkitProvider>
                                        </div>
                                    </CardBody>

                                </Card>

                            </Fragment>
                        )}
                    </Fragment>


                )}
            </div>
        );
    }

    componentDidMount = async () => {
        await axios.get("https://api.bigdatacloud.net/data/client-info")
            .then(async res => {
                const ip_info = res.data
                const headers = {
                    "Content-Type": "multipart/form-data",
                    fingerprint: "123456",
                    Authorization: jwt,
                    "ip": ip_info['ipString'],
                    "device": ip_info['device'],
                    "os": ip_info['os'],
                    "userAgent": ip_info['userAgent'],
                    "isMobile": ip_info['isMobile'],
                    "userAgentDisplay": ip_info['userAgentDisplay'],
                    "userAgentRaw": ip_info['userAgentRaw'],
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
                };

                const params = {
                    api: '/FaceCheckIn'
                }

                // console.log(params)

                await axios
                    .get(`${SERVER}/ip/insert`, {
                        headers: headers,
                        params: params
                    })
                    .then(res => {
                        console.log(res.status)
                    })
            })
        const timestamp = Date.now();
        
        const intervalID = setInterval(() => {
            this.setState({
                intervalID,
                session: timestamp,
                time: new Date(Date.now()).toLocaleTimeString(),
                date_current: new Date(Date.now()).toLocaleDateString(),
            }, () => {
                //   this.props.onfetchDashboard(dataDashboard => {
                //     this.setState({
                //       dataDashboard: dataDashboard,
                //       isLoadingDashboard: false
                //     })
                //   })
                // console.log(this.state.time)
            })
        }, 1000)
        console.log(this.state.time)
        let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
            !window.MSStream
        console.log('abc')
        console.log(window.location.href)
        navigator.geolocation.getCurrentPosition((position) => {            
            const href = window.location.href
            console.log(href)
            const code = (href.split('?')[1]).split('=')[1]
            console.log(code)
            this.props.oncheckQRCodeStudent(code, response => {                
                this.setState({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    isIOS,
                    isLoadingDepartment: false,
                    isLoadingEmployee: false,
                    isAppointmentCode: response.status === 'exists_valid' ? false: true,
                    is_status_check: response.status
                }, () => {
                    if (!this.state.isIOS) {
                        if(response.status === 'exists_valid'){
                            navigator.mediaDevices.getUserMedia({ audio: true, video: { facingMode: 'user' } }, function (stream) {
                                stream.getTracks().forEach(x => x.stop());
                            }, err => console.log(err));
                            this.run();
                        }                        
                    }
                    
                    console.log(this.state.isLoadingEmployee)
                })  
            })            
        });
        // navigator.mediaDevices.getUserMedia({ audio: true, video: { facingMode: 'front' } }, function (stream) {
        //     stream.getTracks().forEach(x => x.stop());
        // }, err => console.log(err));

        // this.run();
    }

    componentWillUnmount() {
        clearInterval(this.time, this.date_current);
    }

}


const mapStateToProps = (state) => {
    return {
        user: state.user,
        errors: state.errors
    }
}

const mapDispatchToProps = dispatch => {
    return {
        oncheckFace: (face, EmpCode, lat, lng, callback) => {
            dispatch(checkFaceStudent(face, EmpCode, lat, lng, callback))
        },
        onfetchFaceDetector: (Avatar, callback) => {
            dispatch(fetchFaceDetector(Avatar, callback))
        },
        onfetchTimesheetPerson: (EmpCode, start_date, end_date, callback) => {
            dispatch(fetchTimesheetPerson(EmpCode, start_date, end_date, callback))
        },        
        oncheckQRCodeStudent: (qr_code, callback) => {
            dispatch(checkQRCodeStudent(qr_code, callback))
        },        
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FaceCheck));
